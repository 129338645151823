import { PersonaType, TenantProvider, TenantSubscriptionMetadata } from './TenantPersona';
import { TenantState } from './TenantState';
import { DisplayData, TenantJobLine } from '~/lib/model';
import Big from 'big.js';
import { ZodVersionedSchema, ZodVersionedMetadata } from '~/lib/zod';
import { TenantId } from '~/tenants/common/registry';
import { TenantOrderContext } from '~/tenants/common/TenantOrderContext';
import { v4 } from 'uuid';
import { BaseSchema } from 'valibot';
import { FileData } from '~/lib/schema/order';
import { MicrositeFileType } from '~microsite/lib/types';

export interface TenantPerformableConfig<
  Schema extends ZodVersionedSchema = ZodVersionedSchema,
  Id extends string = string,
> {
  id: Id;
  tenant: TenantId;
  addons?: string[];
  name: string;
  short: string;
  bullets?: string[];
  long?: string;
  group?: string;
  schema: Schema;
  submit?: BaseSchema<any, any, any>;
  thumbnail?: string | ((context: Record<string, unknown>) => string);
  images: string[];
}

export abstract class TenantJob<
  O extends TenantOrderContext = TenantOrderContext,
  P extends TenantPerformableConfig = TenantPerformableConfig,
  Provider extends TenantProvider = TenantProvider,
> {
  readonly metadata: ZodVersionedMetadata<P['schema']>;
  readonly id: string;

  constructor(
    public readonly order: O,
    id?: string,
    metadata?: ZodVersionedMetadata<P['schema']>,
    public provider?: Provider,
  ) {
    this.id = id ?? v4();
    this.metadata = metadata ?? this.defaultValue();
  }

  abstract get performable(): P;

  defaultValue(): ZodVersionedMetadata<P['schema']> {
    throw new Error(`defaultValue not implemented for ${this.constructor.name}`);
  }

  canAssign(_type: PersonaType, _id: string): boolean {
    return false;
  }

  get name() {
    return this.performable.name;
  }

  get review() {
    return false;
  }

  get estimate(): boolean {
    return this.revenueLines().some((r) => r.estimate) || this.hourly;
  }

  get eligible() {
    return true;
  }

  get hidden() {
    return false;
  }

  get hourly() {
    return false;
  }

  get submission() {
    return false;
  }

  get editing() {
    return false;
  }

  get media(): { persona: PersonaType; microsite?: MicrositeFileType } | null {
    return null;
  }

  get configurable() {
    return false;
  }

  get multiple() {
    return false;
  }

  revenueLines(): TenantJobLine[] {
    return [];
  }

  info(): DisplayData[] {
    return [];
  }

  revenue(discountable?: boolean): Big {
    return this.revenueLines()
      .filter((l) => !discountable || l.discountable)
      .reduce((sum, line) => sum.plus(line.amount), Big(0));
  }

  expenseLines(): TenantJobLine[] {
    return [];
  }

  expense(): Big {
    return this.expenseLines().reduce((sum, line) => sum.plus(line.amount), Big(0));
  }

  onsite(): number {
    return 0;
  }

  offsite(): number {
    return 0;
  }

  get files(): Pick<FileData, 'name' | 'bytes' | 'url' | 'mime'>[] {
    return [];
  }

  states(): TenantState[] {
    return [];
  }

  autoAssign(): { type: PersonaType; id: string } | undefined {
    return undefined;
  }

  subscription(): TenantSubscriptionMetadata | null {
    return null;
  }

  quickbooksProductId(): string | null {
    return null;
  }

  invoiceLine(): string {
    return this.performable.name;
  }
}
