import { TenantOrderLine } from '~/lib/model';
import { Big } from 'big.js';
import TwtJob from '~/tenants/twt/model/TwtJob';
import { schema_latest_version, ZodVersionedMetadata } from '~/lib/zod';
import { TwtOrderType, TwtPropertyType } from '~/tenants/twt/model/TwtOrder';
import { PersonaType } from '~/tenants/common/TenantPersona';
import TwtReelConfig from '~/tenants/twt/performable/reel/TwtReelConfig';
import { MicrositeFileType } from "~microsite/lib/types";

export class TwtEditingJob extends TwtJob<typeof TwtReelConfig> {
  get performable() {
    return TwtReelConfig;
  }

  get hidden(): boolean {
    return true;
  }

  get eligible(): boolean {
    return (
      this.order.metadata.type === TwtOrderType.FOR_SALE_LISTING && this.order.metadata.subtype !== TwtPropertyType.LAND
    );
  }

  defaultValue(): ZodVersionedMetadata<(typeof TwtReelConfig)['schema']> {
    // TODO: type fix?
    return {
      version: schema_latest_version(TwtReelConfig.schema),
    } as any;
  }

  onsite(): number {
    return 30;
  }

  get media() {
    return { persona: PersonaType.PROVIDER, microsite: MicrositeFileType.GALLERY };
  }

  expenseLines(): TenantOrderLine[] {
    return [
      {
        amount: this.orderHasAerialVideo ? (this.orderHasAerialPhoto ? new Big(190) : new Big(185)) : new Big(300),
        description: this.name,
        id: 'reel',
      },
    ];
  }

  revenueLines(): TenantOrderLine[] {
    return [
      {
        amount: this.orderHasAerialVideo ? new Big(225) : new Big(400),
        description: this.name,
        id: 'reel',
      },
    ];
  }
}
